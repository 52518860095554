	.rdTabWrapper{
 
		position: relative;

	}

	.rdTab{ margin-top: 10px !important; }

	.rdTabWrapper ul{

		outline: 0px; 
		display: flex; max-width: 99%; 
		user-select: none; 
		padding-right: 35px; 
		margin: 0px; 
		list-style: none; 
		min-width: 95%; 
		overflow: hidden;

	}

	.rdTabBeforeTitle{ 

		position: absolute; 
		top: 8px; 
		left: -8px; 
		z-index: 2; 


	}

	.react-draggable{

		touch-action: none; 
		max-width: 175px; height: 26px; 
		position: relative; 
		padding-right: 24px; 
		transform: translate(0px, 0px); 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		font-size: 11px; 
		min-width: 0px; 
		margin-left: 30px; 
		padding-left: 15px; 
		flex: 1 1 0%;
	    display: flow-root;
	    list-style: none;

	}

	.rdTabTitle{

		overflow: hidden; 
		color: #fff; 
		width: 90%; 
		margin-top: 4px; 
		text-overflow: ellipsis; 
		cursor: default; 
		text-align: center; 
		float: left; 
		white-space: nowrap;
		text-align: left;

		position: absolute;
	    z-index: 99999;

	    font-size: 12px;
	    text-shadow: 0px 1px #000000;
	   	font-weight: 600;

	}
	
	.rdTabAfterTitle{ 

		position: absolute; 
		top: 8px; 
		right: 16px; 
		z-index: 2; 

	}

	.rdTabCloseIcon{ 

		border-radius: 8px; 
		width: 16px; 
		margin-top: 5px; 
		height: 16px; 
		position: absolute; 
		opacity: 0.2; 
		text-shadow: rgb(255, 255, 255) 0px 1px 0px; 
		font: 16px/1em arial, sans-serif; 
		cursor: pointer; 
		z-index: 999; 
		text-align: center; 
		right: -2px; 
		text-decoration: none; 
		filter: none; 

	}

	.rdTabBefore{ 

		border-radius: 7.5px 0px 0px; 
		width: 25px; 
		left: -14px; 
		height: 26px; 
		position: absolute; 
		transform: skewX(0deg); 
		box-shadow: rgb(72, 72, 72) 1px 1px 0px inset, rgba(0, 0, 0, 0.1) -4px 0px 4px; 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		top: 0px; 

	}

	.rdTabAfter{

		border-radius: 0px 7.5px 0px 0px; 
		width: 25px; 
		height: 26px; 
		position: absolute; 
		transform: skewX(30deg); 
		box-shadow: rgb(72, 72, 72) -1px 1px 0px inset, rgba(0, 0, 0, 0.1) 4px 0px 4px; 
		background-image: linear-gradient(rgb(69, 69, 69), rgb(51, 51, 51)); 
		top: 0px; 
		right: -14px;

	}

	.rdTabAddButton{

		cursor: pointer; 
		font-size: 20px; 
		text-shadow: rgb(255, 255, 255) 0px 1px 0px; 
		position: relative; 
		width: 25px; 
		height: 26px; 
		margin-left: 20px; 
		z-index: 2;

	}

	.rdTabBarAfter{

		left: 0px; 
		height: 5px; position: 
		absolute; top: 26px; 
		background-color: rgb(34, 34, 34); 
		pointer-events: none; 
		border-bottom: 1px solid rgb(17, 17, 17); 
		z-index: 2; 
		right: 0px;

	}

	.react-draggable.ativo{

		background-image: linear-gradient(rgb(39, 39, 39), rgb(32, 33, 36));

	}

	.react-draggable.ativo .rdTabBefore{ 

		background-image: linear-gradient(rgb(39, 39, 39), rgb(32, 33, 36));
		box-shadow: 0px 0px 0px 0px;


	}

	.react-draggable.ativo .rdTabAfter{

		background-image: linear-gradient(rgb(39, 39, 39), rgb(32, 33, 36)); 
		box-shadow: 0px 0px 0px 0px;	

	}

	.react-draggable.ativo{ z-index: 9 !important; }
	

	#selectToolBox{

		position: absolute;
		text-align: center;
		width: 122px;
		border: none;
	    height: 26px;
	    padding: 0px 0px 0px 9px;
	    top: 10px;
        background-image: linear-gradient(rgb(67, 67, 67), rgb(40, 47, 52));
	    border-radius: 0px 10px 0px 0px;
	    background-color: #525b61;
	    color: white;
	    box-shadow: 0px 0px 7px -3px #000;

	}


	.maisFile{

		position: absolute;
	    float: right;
	    color: #fff;
	    font-size: 24px;
	    left: 100%;
	    font-weight: 600;
	    top: 12px;
	    text-shadow: 1px 1px 1px #000;

	}

	.tituloProjeto{

		color:#fff;
		font-size: 17px;
		font-weight: 500;
		margin-top: 10px;

	}






























	